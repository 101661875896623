<template>
    <b-card :ref="sentinalName">
        <div class="mb-4">
            <h5>{{ $t('ARCHIVE.REPORTS.WORKSPACE_FOLDERS_DOCUMENTS.TITLE') }}</h5>
            <small>{{ $t('ARCHIVE.REPORTS.WORKSPACE_FOLDERS_DOCUMENTS.DESCRIPTION') }}</small>
        </div>
        <div class="row row-cols-3">
            <div class="col">
                <div class="form-group">
                    <label>
                        {{ $t('ARCHIVE.REPORTS.WORKSPACE_LABEL') }}
                    </label>
                    <st-autocomplete
                        v-model="workspace"
                        :options="workspacesList"
                        name="workspaces"
                    >
                    </st-autocomplete>
                </div>
            </div>
        </div>
        <div class="my-10">
            <h2 class="text-center">{{totalDocuments}}</h2>
        </div>
        <div id="chart">
            <div v-if="totalDocuments > 0">
                <p class="ml-4 h6 ">
                    {{ $t('ARCHIVE.REPORTS.WORKSPACE_FOLDERS_DOCUMENTS.CHART_LABELS_TITLE') }}
                </p>
            </div>
            <apexchart width="100" :options="options" :series="series"></apexchart>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'WorkspaceFoldersDocuments',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        workspaces: {
            type: Array,
            required: true,
        },
        sentinalName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            workspace: '',
            options: {
                chart: {
                    width: '75%',
                    type: 'pie',
                },
                labels: [],
                legend: {
                    position: 'left'
                },
                theme: {
                    mode: 'light',
                    palette: 'palette3',

                },
            },
            series: [],
            totalDocuments: 0,
            isIntersectingElement: false,
            observer: null
        }
    },
    computed: {
        ...mapGetters({
            folders: 'archive/subfolders/rows',
        }),
        workspacesList() {
            return this.workspaces.map((workspace) => ({
                value: workspace.realName,
                text: workspace.name,
            }))
        }
    },
    watch: {
        workspace(value) {
            this.doFetchFolders({payload: {offset: 0, pageSize: 100}, id: value})
            .then(()=> {
                this.totalDocuments = 0;
                const foldersSeries = [];
                const labels = [];

                this.folders.forEach((folder) => {
                    this.totalDocuments += folder.nrDocumente;
                    foldersSeries.push(folder.nrDocumente)
                    labels.push(folder.name)
                })

                this.options = {...this.options, labels};
                this.series = foldersSeries;
            })
        },
        isIntersectingElement(value, oldValue) {
            this.$emit("on-intersection-element", value);
        },
    },
    mounted() {
        const sentinal = this.$refs[this.sentinalName];

        const handler = (entries) => {
            if (entries[0].isIntersecting) {
                this.isIntersectingElement = true;
                this.workspace = this.workspaces[0]?.realName;
                this.observer.unobserve(entries[0].target);
            } else {
                this.isIntersectingElement = false;
            }
        };
        this.observer = new window.IntersectionObserver(handler);
        this.observer.observe(sentinal);
    },
    methods: {
        ...mapActions({
            doFetchFolders: 'archive/subfolders/getSubfolders',
        }),
    },
}
</script>
