<template>
    <div>
        <Loader v-if="loading['archive/filterStorage']"/>
        <div>
            <p class="font-size-h5 my-4">Dashboard</p>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <workspace-documents :workspaces="workspacesRaportData"/>
            </div>
            <div class="col-12 col-md-4">
                <workspace-dimensions :workspaces="workspacesRaportData"/>
            </div>
            <div class="col-12 col-md-4">
                <expiration />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-6">
                <workspace-folders-documents
                    :workspaces="workspaces"
                    @on-intersection-element="onIntersectionElement"
                    sentinalName="sentinal-workspace-folders-documents"
                    ref="workspace-folders-documents"
                />
            </div>
            <div class="col-12 col-md-6">
                <workspace-folders-dimensions
                    :workspaces="workspaces"
                    ref="workspace-folders-dimensions"
                />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-6">
                <workspace-annual-progress
                    @on-intersection-element="onIntersectionElement"
                    reportType="documents"
                    :workspaces="workspaces"
                    sentinalName="sentinal-workspace-period-progress"
                />
            </div>
            <div class="col-12 col-md-6">
                <workspace-annual-progress
                    reportType="dimension"
                    :workspaces="workspaces"
                    ref="workspace-annual-progress-dimension"
                />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-6">
                <workspace-annual-progress
                    @on-intersection-element="onIntersectionElement"
                    reportType="documents"
                    periodType="monthly"
                    :workspaces="workspaces"
                    sentinalName="sentinal-workspace-monthly-progress"
                />
            </div>
            <div class="col-12 col-md-6">
                <workspace-annual-progress
                    reportType="dimension"
                    periodType="monthly"
                    :workspaces="workspaces"
                    ref="workspace-monthly-progress-dimension"
                />
            </div>
        </div>
    </div>
</template>

<script>
import WorkspaceDimensions from '../components/dashboard/WorkspaceDimensions.vue';
import WorkspaceDocuments from '../components/dashboard/WorkspaceDocuments.vue';
import WorkspaceFoldersDimensions from '../components/dashboard/WorkspaceFoldersDimensions.vue';
import WorkspaceFoldersDocuments from '../components/dashboard/WorkspaceFoldersDocuments.vue';
import WorkspaceAnnualProgress from '../components/dashboard/WorkspaceAnnualProgress.vue';
import Expiration from '../components/dashboard/Expiration.vue';
import { bytesConvertor } from '@/core/helpers/globalMethods';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'Dashboard',
    components: {
        WorkspaceDimensions,
        WorkspaceDocuments,
        WorkspaceFoldersDimensions,
        WorkspaceFoldersDocuments,
        WorkspaceAnnualProgress,
        Expiration,
    },
    data() {
        return {
            seriesType: [20, 80],
            optionsType: {
                labels: ['Stocare Temporara', 'Stocare Permanenta'],
                dataLabels: {
                    enabled: true,
                    position: 'bottom'
                },
                legend: {
                    position: 'left'
                },
            },
            isIntersectingElement: false,
        };
    },
    computed: {
        ...mapGetters({
            workspaces: 'archive/workspaces/rows',
            loading: 'shared/loading',
        }),
        workspacesRaportData() {
            const documentsSeries = [];
            const dimensionsSeries = [];
            const labels = [];
            let totalDocuments = 0;
            let totalDimension = 0;
            this.workspaces.forEach((workspace) => {
                totalDocuments += workspace.nrDocumente;
                totalDimension += workspace.dimension;
                const workspaceDimension = bytesConvertor(workspace.dimension, 'MB', 2);

                documentsSeries.push(workspace.nrDocumente);
                dimensionsSeries.push(workspaceDimension);
                labels.push(workspace.name)
            })
            return {
                documents : {
                    totalDocuments,
                    documentsSeries,
                    labels
                },
                dimensions: {
                    totalDimension: bytesConvertor(totalDimension, 'MB', 2),
                    dimensionsSeries,
                    labels
                }
            }
        }
    },
    methods: {
        ...mapActions({
            doFetch: 'archive/workspaces/getWorkspaces',
            doFetchExpiring: 'archive/documents/getExpiringDocuments',
        }),
        onIntersectionElement(value, sentinel) {
            this.isIntersectingElement = value;
            if(this.isIntersectingElement) {
                if (sentinel === 'sentinal-workspace-period-progress') {
                    this.$refs['workspace-annual-progress-dimension'].setPeriod();
                }
                if (sentinel === 'sentinal-workspace-monthly-progress') {
                    this.$refs['workspace-monthly-progress-dimension'].setPeriod();
                }

                if (this.workspaces) {
                    this.$refs['workspace-folders-dimensions'].doSelectFirstWorkspace();
                }
                
            }
        },
    },
    async created() {
        await this.doFetch({
            offset: 0,
            pageSize: 10
        });
        await this.doFetchExpiring();
    },
    beforeDestroy() {
        this.$refs['workspace-folders-documents'].observer.disconnect();
    }
}
</script>
