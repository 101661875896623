<template>
   <b-card>
        <div class="mb-4">
            <h5>{{ $t('ARCHIVE.REPORTS.EXPIRATION.TITLE') }}</h5>
            <small>{{ $t('ARCHIVE.REPORTS.EXPIRATION.DESCRIPTION') }}</small>
        </div>
        <div id="chart">
            <apexchart type="donut" width="300" :options="optionsTerm" :series="seriesTerm"></apexchart>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';

export default {
    name: 'Expiration',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        
    },
    data() {
        return {
            optionsTerm: {
                dataLabels: {
                    enabled: true,
                    position: 'bottom'
                },
                legend: {
                    position: 'left'
                },
                labels: [ this.$t('ARCHIVE.REPORTS.EXPIRATION.MONTHLY'), this.$t('ARCHIVE.REPORTS.EXPIRATION.WEEKLY') ],
                colors: ['#FFA801', '#F64E60'],
            },
        }
    },
    computed: {
        ...mapGetters({
            expiringCountMonthly: 'archive/documents/expiringCountMonthly',
            expiringCountWeekly: 'archive/documents/expiringCountWeekly',
        }),
        seriesTerm() {
            return [ this.expiringCountMonthly, this.expiringCountWeekly ];
        },
    }
}
</script>
