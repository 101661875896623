<template>
    <b-card>
        <div class="mb-4">
            <h5>{{ $t('ARCHIVE.REPORTS.WORKSPACE_DOCUMENTS.TITLE') }}</h5>
            <small>{{ $t('ARCHIVE.REPORTS.WORKSPACE_DOCUMENTS.DESCRIPTION') }}</small>
        </div>
        <div class="my-10">
            <h2 class="text-center">{{workspaces.documents.totalDocuments}}</h2>
        </div>
        <div id="chart">
            <apexchart  width="320" :options="options" :series="workspaces.documents.documentsSeries"></apexchart>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'WorkspaceDocuments',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        workspaces: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        options() {
            return {
                chart: {
                    width: '320',
                    type: 'pie',
                },
                labels: this.workspaces.documents.labels,
                legend: {
                    position: 'left'
                },
                theme: {
                    mode: 'light',
                    palette: 'palette2',

                },
            }
        }
    },
}
</script>
