<template>
    <b-card>
        <div class="mb-4">
            <h5>{{ $t('ARCHIVE.REPORTS.WORKSPACE_DIMENSION.TITLE') }}</h5>
            <small>{{ $t('ARCHIVE.REPORTS.WORKSPACE_DIMENSION.DESCRIPTION') }}</small>
        </div>
        <div class="my-10">
            <h2 class="text-center">{{workspaces.dimensions.totalDimension}} MB</h2>
        </div>
        <div id="chart">
            <apexchart  width="320" :options="options" :series="workspaces.dimensions.dimensionsSeries"></apexchart>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'WorkspaceDimensions',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        workspaces: {
            type: Object,
            required: true,
        },
    },
    computed: {
        options() {
            return {
                chart: {
                    width: '320px',
                    type: 'pie',
                },
                labels: this.workspaces.dimensions.labels,
                legend: {
                    position: 'left'
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true
                    }
                },
                theme: {
                    mode: 'light',
                    palette: 'palette2',

                },
                tooltip: {
                    enabled: true,
                    theme: false,
                    x: {
                        show: false
                    },
                    y: {
                        formatter: (value) => {
                            return value + ' MB';
                        },
                    }
                }
            }
        },
    },
}
</script>
